import {RoutingPathConfig} from "src/app/shared/configs/routing-path.config";
import {checkHaveServices, checkSectionRole, SectionRole} from "src/app/shared/configs/role.config";
import {MenuItem} from "primeng/api";
import {EsgAssessmentRoutingPathConfig} from "@esg-assessment/shared/configs/esg-assessment-routing.config";
import {ROLE} from "@shared/enums/role.enum";
import {ManagementControlRoutingPathConfig} from "@management-control/shared/configs/management-control-routing.config";
import {IUser} from "@shared/models/user.interface";
import {SERVICE} from "@shared/enums/service.enum";
import {
  MaterialityAnalysisRoutingConfig
} from "@materiality-analysis/shared/configs/materiality-analysis-routing.config";

export const buildMain = (role: ROLE): MenuItem[] => {
  return [
    {
      label: 'Profilo',
      routerLink: [`./${RoutingPathConfig.USER}`],
      icon: "pi pi-user",
      routerLinkActiveOptions: {exact: true}
    },
    {
      label: 'Clienti',
      routerLink: [`./${RoutingPathConfig.CUSTOMERS}`],
      icon: "pi pi-users",
      visible: checkSectionRole(SectionRole.ClientList, role),
      routerLinkActiveOptions: {exact: true}
    },
    {
      label: 'Dashboard',
      routerLink: [`./${RoutingPathConfig.DASHBOARD}`],
      icon: "pi pi-home",
      visible: checkSectionRole(SectionRole.Dashboard, role),
      routerLinkActiveOptions: {exact: true}
    },
  ]
}


export const buildServiceMenuOperator = (currentUser: IUser): MenuItem[] => {
  return [
    {
      label: 'ESG Assessment',
      routerLink: [`/${RoutingPathConfig.ESG_ASSESTMENT}/${EsgAssessmentRoutingPathConfig.SURVEYS}`],
      visible: currentUser.role === ROLE.ADMIN || (checkSectionRole(SectionRole.ESGSurveyList, currentUser.role!) && checkHaveServices(SERVICE.ESG_ASSESSMENT, currentUser)),
      routerLinkActiveOptions: {exact: true}
    },
    {
      label: 'Controllo di gestione',
      routerLink: [`/${RoutingPathConfig.MANAGEMENT_CONTROL}/${ManagementControlRoutingPathConfig.SURVEYS}`],
      visible: currentUser.role === ROLE.ADMIN || (checkSectionRole(SectionRole.ESGSurveyList, currentUser.role!)
        && checkHaveServices(SERVICE.MANAGEMENT_CONTROL, currentUser)),
      routerLinkActiveOptions: {exact: true}
    },
    {
      label: 'Analisi Materialità',
      routerLink: [`/${RoutingPathConfig.MATERIALITY_ANALYSIS}/${MaterialityAnalysisRoutingConfig.SURVEYS}`],
      visible: currentUser.role === ROLE.ADMIN || (checkSectionRole(SectionRole.MaSurveyList, currentUser.role!)
        && checkHaveServices(SERVICE.MATERIALITY_ANALYSIS, currentUser)),
      routerLinkActiveOptions: {exact: true}
    }
  ]
}

