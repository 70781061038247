import {Component, inject} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MenubarModule} from 'primeng/menubar';
import {MenuItem} from 'primeng/api';
import {ButtonModule} from 'primeng/button';
import {MenuModule} from 'primeng/menu';
import {CtxService} from '../../services/ctx.service';
import {AuthService} from '../../services/auth.service';
import {TenantService} from "@shared/services/tenant.service";
import {DialogService} from "primeng/dynamicdialog";
import {TicketModalComponent} from "@shared/components/ticket-modal/ticket-modal.component";
import {RoutingPathConfig} from "@shared/configs/routing-path.config";
import {environment} from "../../../../environments/environment";
import {TagModule} from "primeng/tag";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MenubarModule, ButtonModule, MenuModule, NgOptimizedImage, TagModule],
  template: `
    <div
      class="py-2 px-4 flex align-items-center header sticky top-0 bg-white z-5"
    >
      <div class="flex gap-4 align-items-center">
        <div class="cursor-pointer" (click)="toggleSideBar()">
          <span class="pi pi-bars"></span>
        </div>
        <a [routerLink]="[RoutingPathConfig.DASHBOARD]">
          <img
            alt="{{tenant()?.customer?.name || 'ESG Italia Network'}} Logo"
            width="fit-content"
            height="30px"
            [src]="logo()!"

          />
        </a>
        <p-tag value="AMBIENTE PLAYGROUND" [rounded]="true" severity="contrast"/>
      </div>
      <div class="ml-auto flex gap-2 align-items-center">
        <ng-container *ngIf="isLogged()">
          <div class="user-avatar" (click)="menu.toggle($event)">
            <span>{{ currentUser()?.name?.substring(0, 1)?.toUpperCase() }}{{ currentUser()?.surname?.substring(0, 1)?.toUpperCase() }}</span>
          </div>
        </ng-container>
      </div>

    </div>
    <p-menu #menu [popup]="true" [model]="userMenuItems"></p-menu>
  `,
  styleUrls: [`./header.component.scss`],
})
export class HeaderComponent {

  #dialogService = inject(DialogService);

  items: MenuItem[] = [];
  userMenuItems: MenuItem[];
  stage = environment.STAGE;

  currentUser = this.authService.currentUser;
  isLogged = this.authService.isLogged;
  isSideBarOpen = this.ctxService.displaySideBar;
  logo = this.tenantService.logo;
  tenant = this.tenantService.tenant;

  constructor(
    private ctxService: CtxService,
    private authService: AuthService,
    private tenantService: TenantService
  ) {
    this.userMenuItems = [
      {
        label: 'Esci',
        icon: 'pi pi-sign-out',
        command: () => {
          authService.logout();
        },
      },
    ];
  }

  toggleSideBar(){
    this.ctxService.toggleSideBar(!this.isSideBarOpen())
  }

  openModal() {
    this.#dialogService.open(TicketModalComponent, {
      header: 'Invia nuova segnalazione'
    })
  }

  protected readonly RoutingPathConfig = RoutingPathConfig;
  protected readonly environment = environment;
}
