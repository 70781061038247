import {computed, inject, Injectable, signal} from '@angular/core';
import {catchError, of, tap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {IUser} from '../models/user.interface';
import {toObservable} from "@angular/core/rxjs-interop";
import {environment} from "src/environments/environment";
import {TenantService} from "@shared/services/tenant.service";
import {ClientsService} from "@shared/services/clients.service";
import {ROLE} from "@shared/enums/role.enum";
import {
  buildMain,
  buildServiceMenuOperator,
} from "@shared/configs/menu.config";
import {RoutingPathConfig} from "@shared/configs/routing-path.config";

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  #http = inject(HttpClient);
  #router = inject(Router);
  #clientService = inject(ClientsService);
  #tenantService = inject(TenantService);

  private authPath: string = `${environment.BASE_URL}auth`;
  private userPath: string = `${environment.BASE_URL}user`;

  currentUser = signal<IUser | null>(null);
  isLogged = computed(() => !!this.currentUser())
  currentUserRole = computed(() => this.currentUser()?.role);
  mainMenu = computed(() => buildMain(this.currentUserRole()!));
  serviceMenuOperator = computed(() => buildServiceMenuOperator(this.currentUser()!));

  login(user: Partial<IUser>) {
    return this.#http
      .post<IUser>(this.authPath + '/login', user, {
        headers: {skip: 'true'},
      })
      .pipe(
        tap((res) => {
          localStorage.setItem(environment.ACCESS_TOKEN, `${res.accessToken}`);
          this.getLoggedUser();
        })
      );
  }

  getLoggedUser() {
    if (!this.isLogged() && !!this.#getToken()) {
      return this.#http.get<IUser>(this.userPath + '/logged').pipe(
        tap((user) => {
          localStorage.setItem(environment.ACCESS_TOKEN, `${user.accessToken}`);
          this.currentUser.set(user);
          if (user.customer?.partner?.tenant || user.customer?.tenant) {
            const id = user.role === ROLE.PARTNER ? user.customer.id! : user.customer.partner?.id!
            this.#clientService
              .getTenant(id)
              .subscribe(res => this.#tenantService.setTenant(res));
          }
        }),
        catchError(error => {
          console.error('error', error);
          //this.logout();
          return of(error)
        })
      )
    } else {
      return toObservable(this.currentUser);
    }
  }

  logout() {
    this.#router.navigateByUrl('login').then(() => {
      localStorage.clear();
      this.currentUser.set(null);
      window.location.reload();
    });
  }

  changePassword(data: any) {
    return this.#http.patch(this.userPath + '/change-password' + `/${this.currentUser()?.id}`, data)
  }

  resetPassword(value: Pick<IUser, 'email'>) {
    return this.#http.post(this.authPath + '/reset-password', value)
  }

  confirmRegistration(token: string) {
    return this.#http.post<IUser>(`${this.authPath}/confirm-registration`, {token})
  }

  #getToken() {
    return localStorage.getItem(environment.ACCESS_TOKEN);
  }

  impersonateGenerateOtp() {
    return this.#http.get<any>(this.authPath + '/impersonate/otp')
  }

  impersonate(otp: string, id: string) {
    return this.#http.post<IUser>(this.authPath + '/impersonate' + `/${id}`, {otp}).pipe(
      tap((res) => {
        this.currentUser.set(null);
        localStorage.setItem(environment.ACCESS_TOKEN, `${res.accessToken}`);
        localStorage.setItem(environment.IMPERSONATE, '1');
        this.getLoggedUser().subscribe(() => this.#router.navigate([RoutingPathConfig.DASHBOARD]));
      })
    );
  }


}
