<p-sidebar class="md-sidebar" [visible]="isSideBarOpen()" [modal]="false">
  @if (currentUser()?.customer) {
    <div class="flex flex-column gap-4 mb-4 ">
      <p><small class="font-bold">Denominazione: </small> {{ currentUser()?.customer?.name }}</p>
      <p><small class="font-bold">Partita IVA: </small>{{ currentUser()?.customer?.vatNumber }}</p>
    </div>
  }
  <div class="flex flex-column gap-4">
    <p-menu [model]="mainMenu()" styleClass="w-full"/>
  </div>
  <div class="flex flex-column gap-4">
    <p-menu [model]="serviceMenuOperator()" styleClass="w-full"/>
  </div>
</p-sidebar>
<div class="overlay" (click)="toggleSideBar()" *ngIf="isSideBarOpen()"></div>
