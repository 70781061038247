import { Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
  RouterOutlet,
} from '@angular/router';
import { filter, Observable, tap } from 'rxjs';
import { TenantService } from '@shared/services/tenant.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { CtxService } from '@shared/services/ctx.service';
import { RoutingPathConfig } from '@shared/configs/routing-path.config';
import { FilterService } from 'primeng/api';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ConfirmDialogModule, ToastModule, SpinnerComponent, AsyncPipe],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'esg-platform-fe';
  #ctxService = inject(CtxService);
  #filterService = inject(FilterService);
  isLoading$: Observable<boolean> = this.#ctxService.displayLoading$;

  constructor(private router: Router, private tenantService: TenantService) {
    this.#addEmptyFilter();
    this.router.events.pipe(
      tap((event: any) => this.#navigationInterceptor(event)),
      filter((event: any) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {

        if (event.url.includes(RoutingPathConfig.ERROR)) {
          return;
        }
        // console.log('------------------')
        const url = event.url === '/' ? '' : event.url;
        const urlAndQuery = url.split('?');
//        console.log('[urlAndQuery]', urlAndQuery)

        const pathMap = urlAndQuery[0].split('/');

        // console.log('[pathMap]', pathMap)
        // first element is an empty string, second element of the path segments is the tenant
        let firstPathPart;
        if (pathMap.length > 0) {
          firstPathPart = pathMap[1];
        } else {
          firstPathPart = pathMap[0];
        }

        // console.log('[firstPathPart]', firstPathPart);
        const tenant = this.tenantService.tenant;
        // console.log('[CURRENT TENANT]', tenant())

        if (firstPathPart === undefined && !tenant()) {
          console.log('[TENANT NON TROVATO NELLA URL E NON è SETTATO]', firstPathPart);
          this.tenantService.setTenant();
          //console.log('[TENANT SETTATO]', tenantService.tenant());
          console.log('[REDIRECT TO]', `/${tenant()?.path}`);
          this.router.navigate([`/${tenant()?.path}`]);
          return;
        }

        const tenantFounded = this.tenantService.checkTenantExist({ path: firstPathPart });

        if (!!firstPathPart && tenant() && tenant()?.path !== firstPathPart) {
          console.log('[TENANT TROVATO NELLA URL MA GIA SETTATO]');
          // console.log(this.#addPath(urlAndQuery).replace(`/${firstPathPart}/`, ''))
          const redirectUrl = (tenant()?.path + this.#addPath(urlAndQuery).replace(`/${tenant()!.path}/`, '') + this.#addQuery(urlAndQuery)).replace(/\/\//g, '/');
          console.log('[REDIRECT TO]', redirectUrl);
          this.router.navigate([`/${redirectUrl}`]);
          return;
        }

        if (!!firstPathPart && !tenantFounded && !tenant()) {
          console.log('[TENANT TROVATO NELLA URL MA NON ESISTE]');
          if (!tenant()) {
            this.tenantService.setTenant();
          }
          // finally build url of tenant prefix, path and query params
          // console.log('[CURRENT TENANT]', tenant())
          const redirectUrl = (tenant()?.path + this.#addPath(urlAndQuery) + this.#addQuery(urlAndQuery)).replace(/\/\//g, '/');
          console.log('[REDIRECT TO]', redirectUrl);
          this.router.navigate([`/${redirectUrl}`]);
          return;
        }

        if (!!firstPathPart && tenantFounded && !tenant()) {
          // console.log('[TENANT TROVATO NELLA URL E ESISTE]');
          this.tenantService.setTenant(tenantFounded);
          // console.log('[CURRENT TENANT]', tenant())
          return;
        }

        // console.log('------------------')
      });
  }

  #addPath = (urlAndQuery: string[]) => urlAndQuery[0] ? '/' + urlAndQuery[0] : '';

  #addQuery = (urlAndQuery: string[]) => urlAndQuery[1] ? '?' + urlAndQuery[1] : '';

  #navigationInterceptor(event: RouterEvent): void {


    if (event instanceof NavigationStart) {
      this.#ctxService.startLoading();
    }
    if (event instanceof NavigationEnd) {
      this.#ctxService.finishLoading();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.#ctxService.finishLoading();
    }
    if (event instanceof NavigationError) {
      this.#ctxService.finishLoading();
    }
  }

  #addEmptyFilter() {
    this.#filterService.register('isEmpty', (value: any): boolean => {
      return value == null || value == undefined || value == '';
    });
  }

}

