import {SERVICE} from "@shared/enums/service.enum";

export const environment = {
  BASE_URL: '/api/',
  DEFAULT_TENANT: 'sn',
  DATE_FORMAT: 'dd/MM/yy',
  SERVICE_ENABLED: [SERVICE.ESG_ASSESSMENT, SERVICE.MANAGEMENT_CONTROL, SERVICE.MATERIALITY_ANALYSIS],
  SUPPORT_EMAIL: 'supportosustainabilitynow@bittag.it',
  ACCESS_TOKEN: 'access_token',
  IMPERSONATE: 'impersonate',
  STAGE: 'STAGING'
};
