import {Component, computed, inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarModule} from 'primeng/sidebar';
import {SharedModule} from 'primeng/api';
import {MenuModule} from 'primeng/menu';
import {AuthService} from "@shared/services/auth.service";
import {checkSectionRole, SectionRole} from "@shared/configs/role.config";
import {CheckSectionRoleDirective} from "@shared/directives/check-section-role.directive";

@Component({
  selector: 'app-sidemenu',
  standalone: true,
  imports: [CommonModule, SidebarModule, MenuModule, SharedModule, CheckSectionRoleDirective],
  template: `
    <div class="w-full h-full p-4 white-space-nowrap">
      @if (currentUser()?.customer) {
        <div class="flex flex-column gap-4 mb-4 ">
          <p><small class="font-bold">Denominazione: </small> {{ currentUser()?.customer?.name }}</p>
          <p><small class="font-bold">Partita IVA: </small>{{ currentUser()?.customer?.vatNumber }}</p>
        </div>
      }
      <div class="flex flex-column gap-4">
        <p-menu [model]="mainMenu()" styleClass="w-full"/>
      </div>
      <div class="flex flex-column gap-4">
        <p-menu [model]="serviceMenuOperator()" styleClass="w-full"/>
      </div>
    </div>
  `,
})

export class SidemenuComponent {

  #authService = inject(AuthService);
  currentUserRole = this.#authService.currentUserRole;
  currentUser = this.#authService.currentUser;

  mainMenu = this.#authService.mainMenu
  serviceMenuOperator = this.#authService.serviceMenuOperator

  protected readonly SectionRole = SectionRole;
}
