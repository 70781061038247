import {Component, computed, inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'primeng/api';
import {MenuModule} from 'primeng/menu';
import {SidebarModule} from 'primeng/sidebar';
import {CtxService} from '@shared/services/ctx.service';
import {AuthService} from "@shared/services/auth.service";
import {checkSectionRole, SectionRole} from "@shared/configs/role.config";
import {CheckSectionRoleDirective} from "@shared/directives/check-section-role.directive";

@Component({
  selector: 'app-overlay-sidebar',
  standalone: true,
  imports: [CommonModule, CommonModule, MenuModule, SharedModule, SidebarModule, CheckSectionRoleDirective],
  templateUrl: './overlay-sidebar.component.html',
  styleUrls: ['./overlay-sidebar.component.scss']
})
export class OverlaySidebarComponent {

  #ctxService = inject(CtxService);
  #authService = inject(AuthService);
  currentUser = this.#authService.currentUser;
  currentUserRole = this.#authService.currentUserRole;
  isSideBarOpen = this.#ctxService.displaySideBar

  mainMenu = this.#authService.mainMenu
  serviceMenuOperator = this.#authService.serviceMenuOperator

  toggleSideBar(){
    this.#ctxService.toggleSideBar(!this.isSideBarOpen())
  }

}
